const states = {
  application: {
    value: "application",
    label: "Work in progress application",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 1,
  },
  applied: {
    value: "applied",
    label: "Applied",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 2,
  },
  inPipeline: {
    value: "inPipeline",
    label: "In Pipeline",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 3,
  },
  inPipelineReview: {
    value: "inPipelineReview",
    label: "In Pipeline - Review due",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 4,
  },
  rejected: {
    value: "rejected",
    label: "Rejected",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 5,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 6,
  },
  inWorkplan: {
    value: "inWorkplan",
    label: "In Workplan",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 7,
  },
  published: {
    value: "published",
    label: "Ready for Funding",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 8,
  },
  funded: {
    value: "funded",
    label: "Funded",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 9,
  },
};

module.exports = states;
