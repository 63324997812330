import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import { createI18n } from "vue-i18n";

// Vueform
import Vueform from "@vueform/vueform";
import vueformConfig from "../vueform.config";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { createMetaManager } from "vue-meta";
import vClickOutside from "click-outside-vue3";
import fetchedConfig from "../build/fetched-config.json";
import VueGtag from "vue-gtag";
import messages from "./platforms/locale.js";

console.log("process.env.platform", process.env.VUE_APP_PLATFORM);
console.log("process.env.clientId", process.env.VUE_APP_AAD_CLIENT_ID);

import "@/core/plugins/prismjs";
const app = createApp(App);

store.dispatch("platform/setPlatform", fetchedConfig);
const platform = store.getters["platform/platformId"];

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(createMetaManager());
app.use(vClickOutside);
app.use(Vueform, vueformConfig);

const i18n = createI18n({
  locale: Object.keys(messages).includes(platform) ? platform : "default", // set locale
  messages, // set locale messages
});

app.use(i18n);

const analyticsId = fetchedConfig.ga[process.env.VUE_APP_ENV || "development"];
if (analyticsId) {
  app.use(
    VueGtag,
    {
      config: { id: analyticsId },
    },
    router
  );
}

const faviconLink = document.querySelector("link[rel~='icon']");

if (faviconLink) {
  const favicon = store.getters["platform/config"]?.favicon;
  faviconLink.href = favicon || "/favicon.ico";
}

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
