import axios from "@/core/services/axios";
import _ from "lodash";
import { SET_LIST, CLEAR_LIST, SET_CURRENT } from "./mutation-types";

const state = {
  list: [],
  current: {},
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [CLEAR_LIST](state) {
    state.list = [];
  },
  [SET_CURRENT](state, current) {
    state.current = current;
  },
};

const actions = {
  getSubmissionData({ commit }, id) {
    return axios.get("/users/external/" + id).then((res) => {
      const submission = _.get(res, "data");
      if (submission) {
        commit(SET_CURRENT, submission);
      }
    });
  },
  getListData({ commit }, paginatedPath) {
    let path = `/users/external`;
    if (paginatedPath) {
      path = `${paginatedPath}`;
    }

    return axios.get(path).then((res) => {
      commit(SET_LIST, res.data.items);
      return res;
    });
  },
  editSubmission({ dispatch }, payload) {
    return axios.patch("/users/external/" + payload.id, payload).then(() => {
      dispatch("getSubmissionData", payload.id);
    });
  },
  clearCurrent({ commit }) {
    commit(SET_CURRENT, {});
  },
};

const getters = {
  getAll: (state) => {
    return state.list;
  },
  getCurrentSubmission: (state) => {
    return state.current;
  },
  getById: (state) => {
    return state.current;
  },
  currentSubmissionState: (state) => {
    return state.current?.state;
  },
  currentSubmissionId: (state) => state.current?.id,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
