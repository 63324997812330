export default {
  seekersList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
    list: {
      displayName: "Company Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  intermediaryList: {
    header: {
      title: "Intermediary List",
      counter: "Intermediaries",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Investment Activity", subtitle: "Matched deals" },
      investor: { title: "Investors", subtitle: "Onboarding progress" },
      investee: { title: "Investees", subtitle: "Deal Progress" },
      intermediary: {
        title: "Intermediaries",
        subtitle: "Onboarding progress",
      },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
  },
  investorDetail: {
    potentialDeals: {
      title: "Investee List",
      subtitle: "Investees",
      table: {
        companyName: "Company Name",
        country: "Country",
        sector: "Sector",
        leadEmail: "Exec email",
        action: "Action",
      },
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
  },
  bdrAcronym: "BDR",
  bdrName: "Business Diagnosis Report",
  reassign: {
    header: "Proposal reassignment",
    search: {
      title: "Search Users List",
      p1: "To assign this proposal record to a different user, you must first search for the appropriate user from the list of registered users. Note that only registered users can be granted access to the proposal record.",
      p2: "",
      placeholder: "search by user email",
      select: "Select User",
    },
    popup: {
      message:
        "Are you sure you want to reassign this proposal? This action will transfer ownership of this proposal to ",
    },
  },
};
