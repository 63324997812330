const states = {
  new: {
    value: "new",
    label: "New",
    parent: "new",
    color: "inProgress",
    order: 1,
  },
  review: {
    value: "review",
    label: "Ready for review",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#a0fcca",
    order: 2,
  },
  qualified: {
    value: "qualified",
    label: "Qualified",
    parent: "inProgress",
    color: "inProgress",
    chartColor: "#4fce8a",
    order: 3,
  },
  published: {
    value: "published",
    label: "Listed",
    parent: "published",
    color: "done",
    chartColor: "#1aa85d",
    order: 4,
  },
  delistedSelf: {
    value: "delistedSelf",
    label: "Delisted Self",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 5,
  },
  delistedAdmin: {
    value: "delistedAdmin",
    label: "Delisted by admin",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 6,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 7,
  },
  unqualified: {
    value: "unqualified",
    label: "Unqualified",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 8,
  },
};

module.exports = states;
