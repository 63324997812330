import SHA1 from "crypto-js/sha1";
import _ from "lodash";
import axios from "@/core/services/axios";
import { default as vanillaAxios } from "axios";
import router from "@/router/clean";
import { checkTokenExpiration } from "asokoinsight-tokens/src/helpers";
import config from "@/config/app.config";
import { msalInstance } from "@/config/msal.config";

import {
  SET_CURRENT_USER,
  CLEAR_USER,
  SET_SEARCHED_USER,
  CLEAN_SEARCHED_USER,
} from "./mutation-types";
import { adminUser } from "../../../Constants/userRoles";

const state = {
  currentUser: {},
  searchedUsers: [],
};

const mutations = {
  [SET_CURRENT_USER](state, user) {
    state.currentUser = user;
  },
  [CLEAR_USER](state) {
    state.currentUser = {};
  },
  [SET_SEARCHED_USER](state, user) {
    state.searchedUsers = user;
  },
  [CLEAN_SEARCHED_USER](state) {
    state.searchedUsers = [];
  },
};

const sessionHandlerAd = async ({ commit, dispatch }, data) => {
  const baseURL = config.apiUrl;
  // to avoid triggering authenticated endpoints we need to use the token directly with a vanilla axios instance
  const sessionExchangeResponse = await vanillaAxios.post(
    "/users/exchangeUserType",
    _,
    {
      baseURL: baseURL,
      headers: { Authorization: "Bearer " + data.token },
    }
  );

  // after this, redirect flow works properly

  const user = sessionExchangeResponse.data.user;
  const displayName = user.displayName || user.username;
  let firstName = _.get(user, "data.firstName");
  let lastName = _.get(user, "data.lastName");
  const [givenName, familyName] = (displayName || "").split(" ");
  if (!firstName) {
    firstName = givenName || "n/a";
  }
  if (!lastName) {
    lastName = familyName || "n/a";
  }
  const currentUser = {
    name: displayName,
    firstName,
    lastName,
    email: user.primaryEmail,
    accessToken: sessionExchangeResponse.data.token.accessToken,
    userId: user._id,
    isActiveSubscriber: false,
    roles: user.roles,
  };

  await commit(SET_CURRENT_USER, currentUser);
  await dispatch("tokens/setTokensData", sessionExchangeResponse.data.token, {
    root: true,
  });
};

const sessionHandler = ({ commit, dispatch }, data) => {
  const displayName = data.displayName || data.username;
  const publicRoles = data.roles;
  const email = data.primaryEmail;
  let firstName = _.get(data, "data.firstName");
  let lastName = _.get(data, "data.lastName");
  const [givenName, familyName] = (displayName || "").split(" ");
  if (!firstName) {
    firstName = givenName || "<not specified>";
  }
  if (!lastName) {
    lastName = familyName || "<not specified>";
  }

  const user = {
    name: displayName,
    firstName,
    lastName,
    email,
    accessToken: data.token.accessToken,
    intercomHmac: data.data.intercomHmac,
    userId: data.id,
    isActiveSubscriber: false,
    roles: publicRoles,
    interestId: data.data.interestId,
  };

  commit(SET_CURRENT_USER, user);
  dispatch("tokens/setTokensData", data.token, { root: true });
};

const actions = {
  async storeActiveDirectorySession({ commit, dispatch }, payload) {
    await sessionHandlerAd({ commit, dispatch }, payload);
  },
  async lookupUser(_, payload) {
    const lookupResponse = await axios.get(`/users/lookup-email/${payload}`);
    if (lookupResponse.data !== undefined) {
      return true;
    }
    return false;
  },
  checkAndRefresh({ dispatch }) {
    const refreshExpiresAt = localStorage.getItem("refreshExpiresAt");
    let isExpired = false;
    if (refreshExpiresAt) {
      isExpired = checkTokenExpiration(refreshExpiresAt);
    }
    if (
      refreshExpiresAt &&
      !isExpired &&
      (msalInstance === undefined || msalInstance === null)
    ) {
      return dispatch("tokens/refreshAccessToken", null, { root: true })
        .then(async () => {
          await router.isReady();
          const path = router.currentRoute.value.fullPath || "/";
          // eslint-disable-next-line
          router.push(path).catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          dispatch("tokens/setTokenChecked", null, { root: true });
        });
    } else {
      dispatch("tokens/setTokenChecked", null, { root: true });
      return Promise.resolve();
    }
  },
  login({ commit, dispatch }, payload) {
    return axios
      .post("/users/sessions?v2", {
        login: payload.email,
        password: payload.password,
      })
      .then((res) => {
        sessionHandler({ commit, dispatch }, res.data);
      });
  },
  handleSessionUpdate({ commit, dispatch }, data) {
    sessionHandler({ commit, dispatch }, data);
  },
  handleRefreshFail({ commit }) {
    commit(CLEAR_USER);
    router.push("/");
  },
  async logout({ commit }) {
    await axios.delete("/users/sessions/me");
    await commit(CLEAR_USER);
  },
  register({ commit, dispatch }, userData) {
    const newAccount = { ...userData };

    const emailHash = SHA1(userData.email).toString();
    newAccount.username = `${userData.firstName}_${emailHash}`;

    newAccount.displayName = `${newAccount.firstName}  ${newAccount.lastName}`;

    newAccount.acceptTerms = !!newAccount.acceptTerms;

    delete newAccount.password_confirmation;

    //start HARDCODE
    newAccount.sector = "Advisory - Management Consulting";
    newAccount.userType = adminUser;
    //end HARDCODE

    const analyticsUtm = _.pickBy(
      userData,
      (val, key) => _.startsWith(key, "utm") && val
    );
    if (!_.isEmpty(analyticsUtm)) {
      newAccount.analyticsUtm = analyticsUtm;
    }

    return axios.post("/users/new?v2", newAccount).then((res) => {
      const user = res.data.user;
      const displayName = user.displayName || user.username;
      const publicRoles = user.roles;
      let firstName = _.get(user, "data.firstName");
      let lastName = _.get(user, "data.lastName");
      const [givenName, familyName] = (displayName || "").split(" ");
      if (!firstName) {
        firstName = givenName || "n/a";
      }
      if (!lastName) {
        lastName = familyName || "n/a";
      }
      const currentUser = {
        name: displayName,
        firstName,
        lastName,
        email: user.primaryEmail,
        accessToken: res.data.token.accessToken,
        userId: user._id,
        isActiveSubscriber: false,
        roles: publicRoles,
      };

      commit(SET_CURRENT_USER, currentUser);
      dispatch("tokens/setTokensData", res.data.token, { root: true });
    });
  },
  async clearCurrentUser({ commit, dispatch }) {
    await commit(CLEAR_USER);
    await dispatch("tokens/clearTokens", null, { root: true });
  },
  resetPassword(_, payload) {
    payload.path = `${window.location.origin}/#/new-password/`;
    return axios.post("/users/reset-password", payload);
  },
  searchUser({ commit }, payload) {
    return axios.get(`/users/lookup-external/${payload}`).then((response) => {
      commit(SET_SEARCHED_USER, response.data.items);
    });
  },
  cleanSearch({ commit }) {
    commit(CLEAN_SEARCHED_USER);
  },
};

const getters = {
  currentUser: (state) => state.currentUser,
  isLoggedIn: (state) => !_.isEmpty(state.currentUser),
  userToken: (state) => state.currentUser.accessToken,
  userId: (state) => state.currentUser.userId || "guest",
  userSubmissionId: (state) => state.currentUser.interestId,
  userRoles: (state) => state.currentUser.roles,
  searchedUsers: (state) => state.searchedUsers,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
