export default {
  seekersList: {
    header: {
      title: "SMEs List",
      counter: "SMEs",
    },
    list: {
      displayName: "Organisation Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Business Support Applications",
      counter: "Applications",
    },
  },
  investorList: {
    header: {
      title: "Capital Providers List",
      counter: "Capital Providers",
    },
  },
  intermediaryList: {
    header: {
      title: "Service Providers List",
      counter: "Service Providers",
    },
  },
  matchList: {
    header: {
      title: "Deal Match List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Deal Match", subtitle: "Matched deals" },
      investor: { title: "Capital Providers", subtitle: "Application" },
      investee: {
        title: "Business Support Applications",
        subtitle: "Business Development",
      },
      intermediary: {
        title: "Service Providers",
        subtitle: "Application",
      },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to Applications",
      role: "Application",
    },
  },
  investorDetail: {
    potentialDeals: {
      title: "Investee List",
      subtitle: "Investees",
      table: {
        companyName: "Company Name",
        country: "Country",
        sector: "Sector",
        leadEmail: "Exec email",
        action: "Action",
      },
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to SME's",
      role: "SME",
    },
  },
  bdrAcronym: "HR",
  bdrName: "Health Report",
  usersList: {
    header: {
      title: "Users List",
      counter: "Users",
    },
  },
  userDetail: {
    header: {
      backButton: "Back to Users",
    },
  },
  reassign: {
    header: "Business Support Application Reassignment",
    search: {
      title: "Search Registered Applicant Accounts",
      p1: "Reassign this business support application to a new registered account by transferring access to its details on the public portal. Once reassigned, the previous account will lose access to the application and any associated details they had provided.",
      p2: "Use the search functionality below to find and select the desired registered applicant account. Note that only registered accounts can be assigned business support applications.",
      placeholder: "Search by Applicant Email",
      select: "Select Registered Account",
    },
    popup: {
      message:
        "Are you sure you want to reassign this business support application record? This action will transfer ownership to the selected registered account: ",
    },
  },
};
