import { SET_PLATFORM } from "./mutation-types";

const state = {
  config: {},
};

const mutations = {
  [SET_PLATFORM](state, payload) {
    state.config = payload;
  },
};

const actions = {
  setPlatform({ commit }, payload) {
    commit(SET_PLATFORM, payload);
  },
};

const getters = {
  platformId: (state) => {
    return state.config?.platformId;
  },
  config: (state) => {
    return state.config;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
